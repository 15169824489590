import React from 'react';
import { graphql } from 'gatsby';
import {
  FullWidthBarWithLeaves,
  QuadripleArticleArrangement,
  TripleArticleArrangement,
  CategoryNavigator,
  CategoryCard,
} from '@components';

import styles from './knowledge.module.scss';

export const query = graphql`
  query {
    articles: allStrapiArticle(filter: { status: { eq: "published" } }) {
      edges {
        node {
          id
          description
          title
          strapiId
          slug
          category {
            name
          }
          image {
            id
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    mostRecentArticles: allStrapiArticle(
      filter: { status: { eq: "published" } }
      sort: { fields: created_at, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          id
          description
          title
          strapiId
          slug
          category {
            name
          }
          image {
            id
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    topPageViews: allPageViews(
      filter: { path: { regex: "/article/" } }
      sort: { fields: totalCount, order: DESC }
    ) {
      edges {
        node {
          id
          totalCount
        }
      }
    }
    categories: allStrapiCategory {
      edges {
        node {
          id
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 320) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          name
        }
      }
    }
  }
`;

const Knowledge = ({ data }) => {
  const topPageViews = data.topPageViews.edges;
  const articles = data.articles.edges;
  const mostRecentArticles = data.mostRecentArticles.edges;

  const categories = data.categories.edges.map(category => {
    const {
      node: { id, image, name },
    } = category;
    return (
      <CategoryCard
        id={id}
        image={image}
        title={name}
        // classNames={articleCardStyles}
      />
    );
  });

  const chooseTop3Articles = (allArticles, popularArticles) => {
    const results = [];
    for (const popularArticle of popularArticles) {
      const filteredArticle = allArticles.find(normalArticle =>
        popularArticle.node.id.includes(normalArticle.node.slug)
      );
      if (filteredArticle == null) {
        continue;
      } else {
        results.push(filteredArticle);
      }
      if (results.length >= 3) {
        break;
      }
    }
    return results;
  };

  return (
    <>
      <CategoryNavigator />
      <FullWidthBarWithLeaves classNames={styles}>
        <div className={styles.container}>
          <h2 className={styles.header}>Knowledge</h2>
          <div className={styles.subHeader}>
            <p>
              Welcome to the corner of the internet where I write my articles.
            </p>
          </div>
        </div>
      </FullWidthBarWithLeaves>
      <TripleArticleArrangement articles={mostRecentArticles} />
      <h2 className={styles.header}>Most popular</h2>
      <TripleArticleArrangement
        articles={chooseTop3Articles(articles, topPageViews)}
      />
      <h2 className={styles.header}>Browse By Categories</h2>
      <QuadripleArticleArrangement cards={categories} />
    </>
  );
};

export default Knowledge;
